import "@fontsource/open-sans";
import "@fontsource/m-plus-rounded-1c/700.css";

export const theme = {
  colors: {
    neutral: {
      black: "#231F20",
      white: "#FFFFFF",
      secondary: "#4F5465",
    },
    primary: {
      main: "#3A923A",
      hover: "#277527",
    },
  },
  fonts: {
    main: `"Open-sans", sans-serif`,
    header: `"M PLUS Rounded 1c", sans-serif`,
  },
};
