import styled from "styled-components";

import { device } from "../../utils/media-queries";
import { H2 } from "../../atoms/h2/style";

import SoccerField from "../../images/soccerfield-bg.svg";
import SoccerFieldRotated from "../../images/soccerfield-bg-rot.svg";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary.main};
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  //max-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${SoccerField});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1440px auto;
  padding: 160px 0 33px 0;

  @media ${device.laptop} {
    padding: 160px 80px 33px 80px;
    background-size: 100% auto;
    background-image: url(${SoccerFieldRotated});
  }

  @media ${device.tablet} {
    padding: 160px 40px 33px 40px;
  }

  @media ${device.mobile} {
    padding: 160px 20px 33px 20px;
    background-size: auto 100%;
    background-image: url(${SoccerFieldRotated});
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  max-width: 730px;
  position: relative;
  height: 313px;

  @media ${device.tablet} {
    height: 250px;
  }

  @media ${device.mobile} {
    height: 180px;
  }
`;

export const LottieContainer = styled.div`
  width: 100%;
  max-width: 730px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`;

export const Shadow = styled.img`
  width: 100%;
  max-width: 730px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const Title = styled(H2)`
  max-width: 360px;
  text-align: center;
  margin: 112px 0 128px 0;

  @media ${device.laptop} {
    margin: 112px 0 128px 0;
  }
  @media ${device.tablet} {
    margin: 40px 0 160px 0;
    max-width: 320px;
  }
  @media ${device.mobile} {
    margin: 25px 0 143px 0;
    font-size: 24px;
    line-height: 130%;
    max-width: 220px;
  }
`;
