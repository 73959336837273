import * as React from "react";

import InfoBlocks from "../organisms/info-blocks";
import MainBanner from "../organisms/main-banner";
import Layout from "../templates/layout";

const HomePage = () => {
  return (
    <Layout>
      <MainBanner />
      <InfoBlocks />
    </Layout>
  );
};

export default HomePage;
