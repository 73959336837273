import React from "react";

import { H2 } from "../../atoms/h2/style";
import { Subtitle } from "../../atoms/subtitle/styles";
import {
  BlockbitesLogo,
  Content,
  LineDivider,
  MediumLogo,
  SubtitleM,
  SubtitleS,
  SubtitleXS,
  TextBlockWrapper,
  TextBlockWrapperWide,
  TwitterLogo,
  Wrapper,
} from "./styles";

import DividerIcon from "../../images/dashed-divider.svg";
import MediumLogoSrc from "../../images/medium-logo.svg";
import TwitterLogoSrc from "../../images/twitter-logo.svg";
import BlockbitesLogoSrc from "../../images/blockbites-c-logo.svg";
import { PrimaryBtn } from "../../atoms/primary-button/styles";

const InfoBlocks = () => {
  return (
    <Wrapper>
      <Content>
        <TextBlockWrapperWide>
          <H2>
            This project is the challenge we took at Blockbit.es to be the first
            game on Casper
          </H2>
          <Subtitle grey>
            We will show you how to create a Web3 game on the Casper blockchain.
            We will use the available building blocks provided by the Casper
            ecosystem and see how far we can get!
          </Subtitle>
        </TextBlockWrapperWide>
        <LineDivider src={DividerIcon} />
        <MediumLogo src={MediumLogoSrc} />
        <TextBlockWrapperWide>
          <H2>
            Check the progress on our Medium blog to learn how to build your
            blockchain product.
          </H2>
          <SubtitleM grey>
            We will take you on a journey where we go through ideation, design,
            implementation, tests, and final product launch.
          </SubtitleM>
        </TextBlockWrapperWide>
        <PrimaryBtn
          as="a"
          href="https://medium.com/@theblockbites"
          target="_blank"
        >
          Read more
        </PrimaryBtn>
        <LineDivider src={DividerIcon} />
        <TwitterLogo src={TwitterLogoSrc} />
        <TextBlockWrapper>
          <H2>Do you want to be the first to get in?</H2>
          <SubtitleXS grey>
            Follow us on Twitter to be included in the beta phase.
          </SubtitleXS>
        </TextBlockWrapper>
        <PrimaryBtn
          as="a"
          href="https://twitter.com/theblockbites"
          target="_blank"
        >
          Follow
        </PrimaryBtn>
        <LineDivider src={DividerIcon} />
        <BlockbitesLogo src={BlockbitesLogoSrc} />
        <TextBlockWrapper>
          <H2>BTW there is more!</H2>
          <SubtitleS grey>
            Check out our website to see what other cool things we are working
            on.
          </SubtitleS>
        </TextBlockWrapper>
        <PrimaryBtn as="a" href="https://blockbit.es" target="_blank">
          Learn more
        </PrimaryBtn>
      </Content>
    </Wrapper>
  );
};

export default InfoBlocks;
