import React from "react";

import { Content, Icon, IconsWrapper, Wrapper } from "./styles";

import MediumIcon from "../../images/medium-icon.svg";
import TwitterIcon from "../../images/twitter-icon.svg";

const Navbar = () => {
  return (
    <Wrapper>
      <Content>
        <IconsWrapper>
          <a
            rel="noreferrer"
            href="https://twitter.com/theblockbites"
            target="_blank"
          >
            <Icon src={TwitterIcon} />
          </a>

          <a
            rel="noreferrer"
            href="https://medium.com/@theblockbites"
            target="_blank"
          >
            <Icon src={MediumIcon} />
          </a>
        </IconsWrapper>
      </Content>
    </Wrapper>
  );
};

export default Navbar;
