import styled from "styled-components";

import { device } from "../../utils/media-queries";

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: ${({ theme, grey }) => (grey ? theme.colors.neutral.secondary : null)};

  @media ${device.tablet} {
    font-size: 14px;
  }
`;
