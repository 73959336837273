import styled from "styled-components";

import { device } from "../../utils/media-queries";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  padding-top: 55px;
  display: flex;
  justify-content: flex-end;
  background: transparent;
  padding: 55px 100px 0 100px;

  @media ${device.laptop} {
    padding: 55px 80px 0 80px;
  }
  @media ${device.tablet} {
    padding: 18px 18px 0 20px;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  gap: 28px;
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
`;
