import React from "react";

import { BBLogo, Content, Copyrights, Wrapper } from "./styles";

import BBLogoSrc from "../../images/blockbites-bw-logo.svg";

const Footer = () => {
  return (
    <Wrapper>
      <Content>
        <BBLogo src={BBLogoSrc} />
        <Copyrights>(c) 2022 blockbit.es</Copyrights>
      </Content>
    </Wrapper>
  );
};

export default Footer;
