import styled from "styled-components";

import { device } from "../../utils/media-queries";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral.black};
  padding: 24px 0;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.white};
  padding: 0 100px;

  @media ${device.laptop} {
    padding: 0 80px;
  }
  @media ${device.tablet} {
    padding: 0 24px;
  }
`;

export const BBLogo = styled.img`
  width: 190px;
  height: auto;
`;

export const Copyrights = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;
