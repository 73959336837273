import React from "react";

import { MouseBody, MouseLine } from "./styles";

const ScrollSign = () => {
  return (
    <MouseBody>
      <MouseLine />
    </MouseBody>
  );
};

export default ScrollSign;
