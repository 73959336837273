import styled from "styled-components";

import { device } from "../../utils/media-queries";

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 140%;
  color: ${({ theme, white }) => (white ? theme.colors.neutral.white : null)};
  letter-spacing: -1%;
  font-family: ${({ theme }) => theme.fonts.header};

  @media ${device.tablet} {
    font-size: 28px;
  }

  @media ${device.mobile} {
    font-size: 18px;
  }
`;
