import styled from "styled-components";

export const PrimaryBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 190px;
  height: 55px;
  outline: none;
  border: none;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.neutral.white};
  cursor: pointer;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.main};
  font-weight: 700;
  line-height: 130%;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.colors.primary.hover};
  }
`;
