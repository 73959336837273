import React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import Footer from "../molecules/footer";
import Navbar from "../molecules/navbar/index";
import { theme } from "../utils/theme";

const GlobalStyle = createGlobalStyle`
    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    }
    body {
    overflow-x: hidden;
    color: ${({ theme }) => theme.colors.neutral.black};
    font-family: ${({ theme }) => theme.fonts.main};
    
    }
`;

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Navbar />
        {children}
        <Footer />
      </>
    </ThemeProvider>
  );
};

export default Layout;
