import React from "react";
import Lottie from "react-lottie-player";

import {
  Content,
  LogoContainer,
  LottieContainer,
  Shadow,
  Title,
  Wrapper,
} from "./styles";

import ShadowSrc from "../../images/friendly-soccer-logo-shadow.png";
import ScrollSign from "../../atoms/scroll-sign";
import FSAnim from "../../anim/logo-fs-anim.json";

const MainBanner = () => {
  return (
    <Wrapper>
      <Content>
        <LogoContainer>
          <LottieContainer>
            <Lottie loop animationData={FSAnim} play />
          </LottieContainer>
          <Shadow src={ShadowSrc} />
        </LogoContainer>
        <Title white>is coming to Casper blockchain!</Title>
        <ScrollSign />
      </Content>
    </Wrapper>
  );
};

export default MainBanner;
