import styled from "styled-components";

export const MouseBody = styled.div`
  width: 28px;
  height: 42px;
  border: 2px solid white;
  border-radius: 14px;
  position: relative;
`;

export const MouseLine = styled.div`
  @keyframes moveAnim {
    40% {
      top: 10px;
    }

    80% {
      top: 20px;
    }

    100% {
      top: 10px;
    }
  }
  width: 2px;
  height: 9px;
  background-color: white;
  position: absolute;
  top: 10px;
  left: 11px;
  border-radius: 1px;
  animation: moveAnim;
  animation-duration: 2.6s;
  animation-iteration-count: infinite;
  animation-timing-function: easy-in-out;
`;
