import styled from "styled-components";

import { Subtitle } from "../../atoms/subtitle/styles";
import { device } from "../../utils/media-queries";

import GridPattern from "../../images/grid-pattern.svg";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  ::before {
    content: "";
    background-image: url(${GridPattern});
    background-position: 50% 0;
    opacity: 0.5;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 160px 0 240px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 50%,
    rgba(255, 255, 255, 0) 100%
  );

  @media ${device.laptop} {
    padding: 160px 80px 240px 80px;
  }

  @media ${device.mobile} {
    padding: 56px 20px 160px 20px;
  }
`;

export const TextBlockWrapper = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    gap: 16px;
    margin-bottom: 32px;
  }
`;

export const TextBlockWrapperWide = styled(TextBlockWrapper)`
  max-width: 610px;
`;

export const LineDivider = styled.img`
  width: 5px;
  height: 150px;
  margin: 48px 0;

  @media ${device.tablet} {
    margin: 32px 0;
    height: 80px;
  }
`;

export const MediumLogo = styled.img`
  max-width: 400px;
  width: 100%;
  height: auto;
  margin-bottom: 80px;

  @media ${device.tablet} {
    margin-bottom: 40px;
  }
`;

export const TwitterLogo = styled.img`
  max-width: 126px;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
`;

export const BlockbitesLogo = styled.img`
  max-width: 610px;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
`;

export const SubtitleXS = styled(Subtitle)`
  max-width: 220px;
`;

export const SubtitleS = styled(Subtitle)`
  max-width: 336px;
`;

export const SubtitleM = styled(Subtitle)`
  max-width: 410px;
`;
